import { RealResultsProps } from "@components/sections/productPage/realResults";

import { KeyIngredient } from "@helpers/data/keyIngredients";

import getProduct, {
  getProductById,
  Product,
  ProductName,
  SubscriptionType,
} from "@helpers/getProduct";

import { ProductImages } from "@helpers/getProductDataByName";

import { FaqSectionData } from "@configs/faqSectionData";

import products from "@data/products.json";

import { ProductJson } from "scripts/generateProductsJson";

const getUpsellProducts = (): {
  product: {
    frequentlyBrought: string;
    images: ProductImages | undefined;
    keyIngredients: KeyIngredient[];
    quantity: number;
    color: string;
    oldPrice: number;
    description: string;
    total: number;
    fullIngredients: { title: string; ingredients?: string[]; list?: string[] }[];
    price: number;
    faq: FaqSectionData[];
    name: ProductName;
    qualities: string[];
    realResults: RealResultsProps;
    id: string;
    imageIngredients: string[];
    sku: string;
    slug: string;
    secondName: string;
    type: string;
  };
  quantity: number;
  deliveryFrequency: "NONE" | "MONTHLY" | "EVERY_2_MONTHS" | "EVERY_3_MONTHS";
}[] => {
  const upsellIdProducts = Object.values(products as ProductJson).filter((product) => {
    return product.type === "UPSELL";
  });

  const upsellProducts = upsellIdProducts.map(({ id }) => ({ id, ...getProductById(id) }));

  return upsellProducts.map(({ name, sku, price, id }) => {
    const data = getProduct(name as ProductName, "MONTHLY", 1) || {};

    return {
      product: {
        ...(data as Product),
        oldPrice: (data as Product)?.oldPrice,
        sku,
        price,
        image: (data as Product)?.images.quantityImages[0],
        images: (data as Product)?.images,
        id,
        total: price,
        quantity: 1,
        type: "UPSELL",
        deliveryFrequency: "MONTHLY" as SubscriptionType,
      },
      quantity: 1,
    };
  });
};

export default getUpsellProducts;
