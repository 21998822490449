import React from "react";

import { Link } from "gatsby";

import { CartItem } from "@typings/cart";

interface CartItemNameProps {
  cartItem: CartItem;
  redirectToProductPage: () => void;
}

const CartItemName = ({ cartItem, redirectToProductPage }: CartItemNameProps) => {
  const { product } = cartItem;

  return (
    <button className="cart-item__name" type="button" onClick={redirectToProductPage}>
      {product.name}
    </button>
  );
};

export default CartItemName;
