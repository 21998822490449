import React, { useMemo } from "react";
import ImageGallery, { ImageGalleryItem } from "../ImageGallery";

const srcBase = "/images/common/payment-methods";

const PaymentMethodsGallery = () => {
  const methods: ImageGalleryItem[] = useMemo(() => {
    return ["amex", "diners-club", "discover", "jcb", "mastercard", "visa"].map(
      (cert) => ({
        name: cert,
        src: `${srcBase}/${cert}.png`,
      })
    );
  }, []);

  return <ImageGallery maxHeight={30} images={methods} />;
};

export default PaymentMethodsGallery;
