import React, { FC } from "react";

import { EstimateType } from "@pages/checkout";

import getUpsellProducts from "@helpers/getUpsellProducts";
import { CartItem } from "@components/widgets";

type Props = {
  loading: boolean;
  order: boolean;
  handleEstimate: (value: EstimateType) => Promise<void>;
};

const TopDeals: FC<Props> = ({ loading, order }) => {
  const upsellProducts = getUpsellProducts();

  return (
    <div className="top-deals">
      <h2 className="title">Today’s Top Deals</h2>
      <p className="description">One time add-ons tailored to your pup</p>
      <div className="products">
        {upsellProducts.map((cartItem, index) => (
          <CartItem
            key={index}
            cartItem={cartItem}
            type="ADD"
            loading={loading}
            order={order}
            isTopDeal
          />
        ))}
      </div>
    </div>
  );
};

export default TopDeals;
