import { ProductName } from "@helpers/getProduct";

/* eslint-disable import/prefer-default-export */
/* eslint-disable import/prefer-default-export */
export interface KeyIngredient {
  ingredient: string;
  image: string;
  description: {
    descriptionText: string;
    list?: string[];
  };
}

export type KeyIngredients = {
  [name in Exclude<ProductName, "Anti-Anxiety Calming Bed">]: KeyIngredient[];
};

export const keyIngredients: KeyIngredients = {
  "Calming Chews": [
    {
      ingredient: "Proprietary Blend of (B. longum, L. helveticus)",
      image: "",
      description: {
        descriptionText:
          "Derived from a selected blend of freeze-dried probiotic cultures, including viable strains of Lactobacillus helveticus and Bifidobacterium longum, this proprietary blend of probiotics offers 3 billion FU per soft chew while helping to support a relaxed disposition.",
      },
    },
    {
      ingredient: "Passion Flower Powder (Passiflora Incarnata)",
      image: "",
      description: {
        descriptionText:
          "This herbal supplement is made of flowers, leaves, and stems of the plant and can promote the level of gamma- aminobutyric acid (GABA) in your dog's brain. This helps support brain function while encouraging your dog to relax and enjoy deep, restful sleep.",
      },
    },
    {
      ingredient: "Shoden® Ashwagandha",
      image: "",
      description: {
        descriptionText:
          "Added to our advanced Calming Chews to help support canine mental well-being and reduce stress levels, this ingredient is a highly potent extract from the ashwagandha plant (roots and leaves) that can maintain your dog’s emotional balance, offering a calming effect and supporting focus.",
      },
    },
    {
      ingredient: "(Suntheanine®) L-Theanine",
      image: "",
      description: {
        descriptionText:
          "Suntheanine® L-Theanine is produced via a unique process that mimics the natural process in green tea leaves, resulting in a 100% pure L-isomer-theanine. It stimulates a dog to release serotonin and dopamine while stimulating activity in the brain known as alpha waves - which are associated with a relaxed but alert mental state.",
      },
    },
    {
      ingredient: "Gamma-aminobutyric acid (GABA)",
      image: "",
      description: {
        descriptionText:
          "Gamma-aminobutyric acid (GABA) is a key neurotransmitter that plays a pivotal role in helping balance your dog's behavior and emotional state.",
      },
    },
  ],
  "Joint Care Chews": [
    {
      ingredient: "Glucosamine HCI",
      image: "",
      description: {
        descriptionText:
          "Glucosamine HCI has been shown to help maintain lubricated joints, to lessen friction, and therefore target occasional joint stiffness.",
        list: ["Can help lubricate joints.", "Targets occasional stiffness"],
      },
    },
    {
      ingredient: "MMP-50™ (Green Lipped Mussel)",
      image: "",
      description: {
        descriptionText:
          "This unique source of Green Lipped Mussels is sourced directly from the clean, crisp waters of New Zealand. Thanks to its ability to support a normal inflammatory response it can help with occasional joint discomfort, encourage hip and joint flexibility and comfort during movement.",
        list: ["Packed with beneficial omega-3 fatty acids.", "Targets occasional discomfort."],
      },
    },
    {
      ingredient: "Anchovy Oil",
      image: "",
      description: {
        descriptionText:
          "A rich source of omega fatty acids, anchovy oil can help support muscular strength and a normal inflammatory response.",
        list: [
          "Rich source of omega fatty acids",
          "Supports muscular health.",
          "Supports a normal inflammatory response.",
        ],
      },
    },
    {
      ingredient: "Calcium Fructoborate",
      image: "",
      description: {
        descriptionText:
          "Calcium fructoborate can support with occasional joint and hip discomfort, mobility, and a proper range of motion during activity.",
        list: [
          "Supports joint and hip discomfort.",
          "Supports mobility.",
          "Supports a range of motion.",
        ],
      },
    },
    {
      ingredient: "Curcugen® (Turmeric Curcumin)",
      image: "",
      description: {
        descriptionText:
          "Curcugen® supports a normal inflammatory response, healthy bone and joint function and connective tissue health. Plus, it's 39 times easier for your dog to absorb than Turmeric Curcumin alone.",
        list: [
          "Supports a normal inflammatory response.",
          "Supports bone and joint function.",
          "Supports connective tissue health.",
        ],
      },
    },
  ],
  "Probiotic Chews": [
    {
      ingredient: "Inulin (Agave)",
      image: "",
      description: {
        descriptionText:
          "This powerful ingredient has a high volume of inulin fiber, which can help boost several aspects of the digestive system. Acting as an efficacious prebiotic, inulin can encourage the production of good bacteria within the gut, helping to support your pup's digestive system.",
        list: [
          "Helps food pass through the digestive tract.",
          "Rich in inulin fiber.",
          "Encourages healthy bacteria growth.",
        ],
      },
    },
    {
      ingredient: "Bacillus coagulans",
      image: "",
      description: {
        descriptionText:
          "Found in the intestine, Bacillus coagulans (B. coagulans) is a bacteria known for supporting a dog's immune system. This probiotic is a beneficial microorganism that can maintain the immune system and response, supporting occasional discomforts.",
        list: [
          "Effectively maintains healthy bowel movements.",
          "Supports proper digestion and intestinal balance.",
          "Digestive aid.",
        ],
      },
    },
    {
      ingredient: "Honey",
      image: "",
      description: {
        descriptionText:
          "Full of antioxidants, nutrients, and probiotics, honey is a wonderful and powerful ingredient in our probiotic chews. This sweet addition can help provide your pet with bacterial balancing properties and a normal inflammatory response that can help support digestive tract health.",
        list: [
          "Helps food pass through the digestive tract.",
          "Rich in inulin fiber.",
          "Encourages healthy bacteria growth.",
        ],
      },
    },
    {
      ingredient: "Pumpkin",
      image: "",
      description: {
        descriptionText:
          "Pumpkin produces beneficial fatty acids that stimulate intestinal sodium. Plus, this colorful fruit contains properties that can help optimize stool consistency by absorbing water, supporting healthy bowel movements and digestion.",
        list: [
          "Can help bulk loose stools.",
          "Helps with maintaining a normal pH level in the large intestine",
          "Supports healthy digestion.",
        ],
      },
    },
  ],
  "Skin & Coat Chews": [
    {
      ingredient: "Omega Fatty Acids (Anchovy & Flaxseed Oil)",
      image: "",
      description: {
        descriptionText:
          "Both Flaxseed Oil and Anchovy Oil are immensely good sources of Omega 3 & 6 fatty acids that can directly support skin health. Plus they encourage a normal inflammatory response.",
        list: [
          "Maintains a healthy coat and skin.",
          "Rich in beneficial omega-3 fatty acids.",
          "Packed with vital vitamins and minerals to support coat health.",
        ],
      },
    },
    {
      ingredient: "Apple Cider Vinegar",
      image: "",
      description: {
        descriptionText:
          "Packed with antioxidants and healthy microbial supporting properties, organic apple cider vinegar can help support the normal immune response while supporting occasional itchy and dry skin conditions caused by bacteria and external stressors.",
        list: [
          "Helps maintain the normal moisture content of the skin.",
          "Perfect for animals with sensitive skin.",
          "Supports skin and coat health.",
        ],
      },
    },
    {
      ingredient: "Vitamin E",
      image: "",
      description: {
        descriptionText:
          "Vitamin E can help support a normal immune response and promote healthy skin. Many dogs in the USA require additional Vitamin E supplementation, so add this key vitamin can be extremely beneficial.",
        list: [
          "Vital vitamins are needed to help maintain a normal immune response.",
          "Targets external and internal irritants.",
          "Packed with antioxidant properties.",
        ],
      },
    },
  ],
};
