import React from "react";

import { CartItem } from "@typings/cart";

import CartItemTotal from "./total/CartItemTotal";
import CartItemName from "./name/CartItemName";

interface CartItemHeaderProps {
  cartItem: CartItem;
  redirectToProductPage: () => void;
}

const CartItemHeader = ({ cartItem, redirectToProductPage }: CartItemHeaderProps) => {
  const { product } = cartItem;

  return (
    <div className="cart-item__header">
      <div className="cart-item__title">
        <CartItemName cartItem={cartItem} redirectToProductPage={redirectToProductPage} />
        <CartItemTotal cartItem={cartItem} />
      </div>

      <div className="cart-item__description">{product.additionalDescription}</div>
    </div>
  );
};

export default CartItemHeader;
