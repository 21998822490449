import React, { SVGProps } from "react";

import ArrowLeftIcon from "@assets/svg/common/arrow-left.svg";
import ArrowRightIcon from "@assets/svg/common/arrow-right.svg";
import CartIcon from "@assets/svg/header/cart.svg";
import ChevronIcon from "@assets/svg/common/chevron.svg";
import CrossIcon from "@assets/svg/common/cross.svg";
import CustomerServiceIcon from "@assets/svg/checkout/customer-service.svg";
import FastShipIcon from "@assets/svg/checkout/fast-ship.svg";
import GuaranteeIcon from "@assets/svg/checkout/guarantee.svg";
import InputArrowRightIcon from "@assets/svg/common/input-arrow-right.svg";
import LogoIcon from "@assets/svg/common/logo.svg";
import MinusIcon from "@assets/svg/common/minus.svg";
import PawIcon from "@assets/svg/common/paw.svg";
import PlusIcon from "@assets/svg/common/plus.svg";
import ProfileIcon from "@assets/svg/header/profile.svg";
import QualityAssuredIcon from "@assets/svg/checkout/quality-assured.svg";
import StarIcon from "@assets/svg/common/star.svg";
import DogIcon from "@assets/svg/common/dog.svg";
import ManufacturedIcon from "@assets/svg/approach/manufactured.svg";
import DrugFreeIcon from "@assets/svg/approach/drug-free.svg";
import ApproachIcon from "@assets/svg/approach/approach.svg";
import CheckMarkIcon from "@assets/svg/common/check.svg";
import QuestionMarkIcon from "@assets/svg/common/question.svg";
import LockIcon from "@assets/svg/common/lock.svg";
import VerifiedIcon from "@assets/svg/common/verified.svg";
import FirstMonthIcon from "@assets/svg/benefits/firstMonth.svg";
import SecondMonthIcon from "@assets/svg/benefits/secondMonth.svg";
import ThirdMonthIcon from "@assets/svg/benefits/thirdMonth.svg";
import FreeGiftIcon from "@assets/svg/common/free-gift.svg";
import FlameIcon from "@assets/svg/common/flame.svg";
import CircleCheckMarkIcon from "@assets/svg/common/circle-check-mark.svg";
import ShieldCheckMarkIcon from "@assets/svg/common/shield-check-mark.svg";
import DeliveryIcon from "@assets/svg/common/delivery.svg";
import TrashIcon from "@assets/svg/common/trash.svg";

/* Health Icons */
import ArrowDownIcon from "@assets/svg/health/Arrow-down.svg";
import HealthArrowRightIcon from "@assets/svg/health/Arrow-right.svg";
import BandageIcon from "@assets/svg/health/Bandage.svg";
import BoldIcon from "@assets/svg/health/Bold.svg";
import BurgerIcon from "@assets/svg/health/Burger.svg";
import BoneIcon from "@assets/svg/health/Bone.svg";
import HealthCartIcon from "@assets/svg/health/Cart.svg";
import HealthCheckIcon from "@assets/svg/health/Check.svg";
import DentalIcon from "@assets/svg/health/Dental.svg";
import HealthDogIcon from "@assets/svg/health/Dog.svg";
import DropIcon from "@assets/svg/health/Drop.svg";
import EyeIcon from "@assets/svg/health/Eye.svg";
import FrameIcon from "@assets/svg/health/Frame.svg";
import GiftIcon from "@assets/svg/health/Gift.svg";
import HealthPawIcon from "@assets/svg/health/Paw.svg";
import PlantIcon from "@assets/svg/health/Plant.svg";
import HealthPlusIcon from "@assets/svg/health/Plus.svg";
import PoopIcon from "@assets/svg/health/Poop.svg";
import ShieldIcon from "@assets/svg/health/Shield.svg";
import SleepIcon from "@assets/svg/health/Sleep.svg";
import VolumeIcon from "@assets/svg/health/Volume.svg";
import WeightIcon from "@assets/svg/health/Weight.svg";
import SmArrowRightIcon from "@assets/svg/common/sm-arrow-right.svg";
import CircleArrowRightIcon from "@assets/svg/common/circle-arrow-right.svg";
import BoxCtaIllustrationIcon from "@assets/svg/box/cta/illustration.svg";
import BoxPopupIllustrationIcon from "@assets/svg/box/popup/illustration.svg";

/* Wizard Icons */
import Jar1Icon from "@assets/svg/wizard/jar1.svg";
import Jar2Icon from "@assets/svg/wizard/jar2.svg";
import Jar3Icon from "@assets/svg/wizard/jar3.svg";

/* Plans Icons */
import Month12Icon from "@assets/svg/plans/month-12.svg";
import Month6Icon from "@assets/svg/plans/month-6.svg";
import MonthlyIcon from "@assets/svg/plans/monthly.svg";

/* Box Benefits Icons */
import BoxBenefit1Icon from "@assets/svg/box/benefits/benefit1.svg";
import BoxBenefit2Icon from "@assets/svg/box/benefits/benefit2.svg";
import BoxBenefit3Icon from "@assets/svg/box/benefits/benefit3.svg";

/* Product Icons */
import SaleIcon from "@assets/svg/product/sale.svg";

const allowedIcons = [
  "arrow-left",
  "arrow-right",
  "cart",
  "chevron",
  "cross",
  "customer-service",
  "fast-ship",
  "guarantee",
  "input-arrow-right",
  "logo",
  "minus",
  "paw",
  "plus",
  "profile",
  "quality-assured",
  "star",
  "dog",
  "manufactured",
  "drug-free",
  "approach",
  "check-mark",
  "question-mark",
  "lock",
  "verified",
  "first-month",
  "second-month",
  "third-month",
  "free-gift",
  "arrow-down",
  "health-arrow-right",
  "bandage",
  "bold",
  "burger",
  "bone",
  "health-cart",
  "health-check",
  "dental",
  "health-dog",
  "drop",
  "eye",
  "frame",
  "gift",
  "health-paw",
  "plant",
  "health-plus",
  "poop",
  "shield",
  "sleep",
  "volume",
  "weight",
  "jar1",
  "jar2",
  "jar3",
  "month6",
  "month12",
  "monthly",
  "box-benefit1",
  "box-benefit2",
  "box-benefit3",
  "sm-arrow-right",
  "box-cta-illustration",
  "box-popup-illustration",
  "circle-arrow-right",
  "flame",
  "sale",
  "circle-check-mark",
  "shield-check-mark",
  "delivery",
  "trash",
] as const;

export type IconName = (typeof allowedIcons)[number];

interface Props extends SVGProps<SVGSVGElement> {
  name: IconName;
  className?: string;
}

type Icons = {
  [name in IconName]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const icons: Icons = {
  "arrow-left": ArrowLeftIcon,
  "arrow-right": ArrowRightIcon,
  "customer-service": CustomerServiceIcon,
  "fast-ship": FastShipIcon,
  "input-arrow-right": InputArrowRightIcon,
  "quality-assured": QualityAssuredIcon,
  cart: CartIcon,
  chevron: ChevronIcon,
  cross: CrossIcon,
  guarantee: GuaranteeIcon,
  logo: LogoIcon,
  minus: MinusIcon,
  paw: PawIcon,
  plus: PlusIcon,
  profile: ProfileIcon,
  star: StarIcon,
  dog: DogIcon,
  manufactured: ManufacturedIcon,
  "drug-free": DrugFreeIcon,
  approach: ApproachIcon,
  "check-mark": CheckMarkIcon,
  "question-mark": QuestionMarkIcon,
  lock: LockIcon,
  verified: VerifiedIcon,
  "first-month": FirstMonthIcon,
  "second-month": SecondMonthIcon,
  "third-month": ThirdMonthIcon,
  "free-gift": FreeGiftIcon,
  "arrow-down": ArrowDownIcon,
  "health-arrow-right": HealthArrowRightIcon,
  bandage: BandageIcon,
  bold: BoldIcon,
  burger: BurgerIcon,
  bone: BoneIcon,
  "health-cart": HealthCartIcon,
  "health-check": HealthCheckIcon,
  dental: DentalIcon,
  "health-dog": HealthDogIcon,
  drop: DropIcon,
  eye: EyeIcon,
  frame: FrameIcon,
  gift: GiftIcon,
  "health-paw": HealthPawIcon,
  plant: PlantIcon,
  "health-plus": HealthPlusIcon,
  poop: PoopIcon,
  shield: ShieldIcon,
  sleep: SleepIcon,
  volume: VolumeIcon,
  weight: WeightIcon,
  jar1: Jar1Icon,
  jar2: Jar2Icon,
  jar3: Jar3Icon,
  month12: Month12Icon,
  month6: Month6Icon,
  monthly: MonthlyIcon,
  "box-benefit1": BoxBenefit1Icon,
  "box-benefit2": BoxBenefit2Icon,
  "box-benefit3": BoxBenefit3Icon,
  "sm-arrow-right": SmArrowRightIcon,
  "box-cta-illustration": BoxCtaIllustrationIcon,
  "box-popup-illustration": BoxPopupIllustrationIcon,
  "circle-arrow-right": CircleArrowRightIcon,
  flame: FlameIcon,
  sale: SaleIcon,
  "circle-check-mark": CircleCheckMarkIcon,
  "shield-check-mark": ShieldCheckMarkIcon,
  delivery: DeliveryIcon,
  trash: TrashIcon,
};

const Icon: React.FC<Props> = ({ name, className, ...props }) => {
  const IconByType = icons[name];

  if (!IconByType) return null;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconByType name={name} className={className} {...props} />;
};

export default Icon;
