import React, { Dispatch, FC, SetStateAction, useEffect } from "react";

import { useFormikContext } from "formik";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import * as yup from "yup";

import PlacesAutocomplete from "@components/common/PlacesAutocomplete";
import InputField from "@components/common/InputField";
import CheckboxField from "@components/common/CheckboxField";
import PhoneInput from "@components/common/PhoneInput";
import SelectField from "@components/common/SelectField";

import { stateOptions } from "@helpers/data/stateOptions";

import type { CheckoutFormValues } from "@components/checkout/CheckoutFormContainer";
import { CartItem } from "@typings/cart";
import { trackStartedCheckout } from "@analytics/klaviyo";

interface Props {
  formType: "information" | "payment";
  focus?: string;
  setFocus: Dispatch<SetStateAction<string | undefined>>;
  orderId: string;
  cartItems: CartItem[];
}

const CheckoutInformationForm: FC<Props> = ({ formType, focus, setFocus, orderId, cartItems }) => {
  const { values, setValues, setFieldValue } = useFormikContext<CheckoutFormValues>();
  const isAutoCompleteDisable = process.env.GATSBY_GOOGLE_AUTOCOMPLETE_DISABLE === "true";

  useEffect(() => {
    return () => {
      setFocus(undefined);
    };
  }, [setFocus]);

  // eslint-disable-next-line consistent-return
  const handleChange = (data: any, type?: string): void => {
    if (typeof data === "string") {
      return setFieldValue(type === "shipping" ? "streetAddress" : "diffStreetAddress", data);
    }

    const label = data?.structured_formatting?.main_text;

    setFieldValue(type === "shipping" ? "streetAddress" : "diffStreetAddress", label);

    void geocodeByPlaceId(data.place_id).then((res) => {
      const addressComponents = res?.[0]?.address_components || [];

      const postalCode = addressComponents.find((c) => c.types.includes("postal_code"))?.short_name;
      const state = addressComponents.find((c) =>
        c.types.includes("administrative_area_level_1")
      )?.short_name;
      const city = addressComponents.find(
        (c) => c.types.includes("locality") && c.types.includes("political")
      )?.long_name;

      setValues({
        ...values,
        [type === "shipping" ? "streetAddress" : "diffStreetAddress"]: label,
        [type === "shipping" ? "zipCode" : "diffZipCode"]: postalCode || "",
        [type === "shipping" ? "state" : "diffState"]: state || "",
        [type === "shipping" ? "city" : "diffCity"]: city || "",
      });
    });
  };

  const handleEmailOnBlur = (): void => {
    const { email } = values;

    yup
      .string()
      .email("Invalid email")
      .required()
      .validate(email)
      .then(() => {
        trackStartedCheckout({
          email,
          orderId,
          cartItems,
        });
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  if (formType !== "information") return null;

  return (
    <div className="form-content information-form">
      <span className="description">Contact Information</span>

      <div className="row">
        <InputField
          label="Email"
          name="email"
          type="email"
          autoFocus={focus === "email"}
          onBlur={handleEmailOnBlur}
        />

        <PhoneInput autoFocus={focus === "phoneNumber"} className="phone-number" />
      </div>

      <span className="description">Shipping Address</span>

      <div className="row">
        <InputField label="First name" name="firstName" autoFocus={focus === "firstName"} />
        <InputField label="Last name" name="lastName" />
      </div>

      <div className="row">
        {isAutoCompleteDisable ? (
          <InputField label="Street Address" name="streetAddress" />
        ) : (
          <PlacesAutocomplete
            value={values.streetAddress}
            onChange={(data) => handleChange(data, "shipping")}
            label="Street Address"
          />
        )}
        <SelectField placeholder="State" name="state" options={stateOptions} animatedPlaceholder />
      </div>

      <div className="row">
        <InputField
          autoComplete="street-address2"
          label="Apartment, suite, building (optional)"
          name="streetAddress2"
        />
      </div>

      <div className="row">
        <InputField label="Town / City" name="city" autoFocus={focus === "city"} />
        <InputField label="Country" name="country" disabled />
        <InputField label="Zip Code" name="zipCode" />
      </div>
      <CheckboxField
        name="isDifferentAddress"
        label="Use a different billing address"
        className="form-checkbox"
      />
      {values.isDifferentAddress && (
        <div className="different-address-fields">
          <div className="row">
            {isAutoCompleteDisable ? (
              <InputField label="Street Address" name="diffStreetAddress" />
            ) : (
              <PlacesAutocomplete
                value={values.diffStreetAddress}
                onChange={(data) => handleChange(data, "billing")}
                label="Street Address"
              />
            )}

            <SelectField
              placeholder="State"
              name="diffState"
              options={stateOptions}
              animatedPlaceholder
            />
          </div>

          <div className="row">
            <InputField label="Apartment, suite, building (optional)" name="diffStreetAddress2" />
          </div>

          <div className="row">
            <InputField label="Town / City" name="diffCity" />
            <InputField label="Country" name="diffCountry" disabled />
            <InputField label="Zip Code" name="diffZipCode" />
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutInformationForm;
