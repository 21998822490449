import { BASE_URL } from "@constants/common";
import { CartItem } from "@typings/cart";

export const transformCartItemsToUrl = (cartItems: CartItem[]) => {
  return `${BASE_URL}/cart/?products=${encodeURIComponent(
    JSON.stringify(
      /* Mapping, because we don't want needless information
        such as "faq, images, description and etc" */
      cartItems.map((item) => ({
        isFree: item.isFree,
        linkedTo: item.linkedTo,
        promos: item.promos,

        quantity: item.quantity,
        product: {
          id: item.product.id,
        },
      }))
    )
  )}`;
};
