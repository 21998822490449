import React from "react";

import { navigate } from "gatsby";

import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { closeCart, selectAvailableBOGOFreeProducts } from "@store/cart";
import { FREE_NOTIFIERS_MAP } from "@constants/cart";

import CartItem from "../item/CartItem";

const CartNotifiers = () => {
  const availableBOGOFreeProducts = useSelector(selectAvailableBOGOFreeProducts);

  const dispatch = useDispatch();

  const redirectToProductPage = (slug: string) => {
    if (slug) {
      void navigate(`/product/${slug}`);
    }

    dispatch(closeCart());
  };

  return availableBOGOFreeProducts.map((type, index) => {
    const cartItem = FREE_NOTIFIERS_MAP[type];

    return (
      <React.Fragment key={`${type}-${index}`}>
        <button
          type="button"
          className="cart-notifier"
          onClick={() => redirectToProductPage(cartItem.product.slug)}
        >
          <CartItem
            className={classNames("--notifier", { "--is-bed-notifier": type === "BED" })}
            cartItem={cartItem}
            hideCounter
            hideRemoveBtn
          />
        </button>

        <hr className="cart-item__separator" />
      </React.Fragment>
    );
  });
};

export default CartNotifiers;
