import React, { FC } from "react";

import classNames from "classnames";

import { CheckoutFormType } from "@pages/checkout";

import Icon from "@components/common/Icon";

interface Props {
  formType: CheckoutFormType;
  setFormType: (newFormType: CheckoutFormType) => void;
}

const FormTypeBreadcrumb: FC<Props> = ({ formType, setFormType }) => {
  return (
    <div className="form-type-breadcrumb">
      <button
        className={classNames("breadcrumb-item", { active: formType === "information" })}
        type="button"
        onClick={() => setFormType("information")}
      >
        Information
      </button>
      <Icon name="chevron" className="arrow" />
      <button
        className={classNames("breadcrumb-item", { active: formType === "payment" })}
        type="button"
        onClick={() => setFormType("payment")}
      >
        Payment
      </button>
    </div>
  );
};

export default FormTypeBreadcrumb;
