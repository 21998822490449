/* eslint-disable react/no-unescaped-entities */
import React from "react";

import { navigate } from "gatsby";

import { formatTimeInMs } from "@utils/formatTimeInMs";

import useModal from "@hooks/useModal";

import Modal from "@components/modal";
import { Button } from "@components/common";

import CheckoutLeaveBadge from "./badge/CheckoutLeaveBadge";

interface CheckoutLeaveProps {
  time: number;
  location: any;
}

const CheckoutLeave = ({ time, location }: CheckoutLeaveProps) => {
  const { setIsOpen } = useModal();

  const { hours, minutes, seconds } = formatTimeInMs(time);

  const leaveTo = location?.search?.includes("wizard") ? "/box" : "/";

  return (
    <Modal>
      <div className="checkout-leave">
        <h2 className="checkout-leave__title">
          You haven't finished checking out yet. Don't miss out on
        </h2>

        <div className="checkout-leave__badges">
          <CheckoutLeaveBadge
            color="green"
            icon="delivery"
            subtitle="Limited offer"
            title="Free shipping"
          />

          <CheckoutLeaveBadge
            icon="sale"
            subtitle={`${hours}:${minutes}:${seconds}`}
            title={
              <React.Fragment>
                Save <span>$26.99</span>
              </React.Fragment>
            }
          />
        </div>

        <div className="checkout-leave__btns">
          <Button color="primary-dark" onClick={() => setIsOpen(false)}>
            Keep checking out
          </Button>

          <Button color="secondary" onClick={() => navigate(leaveTo)}>
            Return to cart
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CheckoutLeave;
