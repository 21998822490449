import { RealResultsProps } from "@components/sections/productPage/realResults";

import { FaqSectionData } from "@configs/faqSectionData";

import products from "@data/products.json";

import { ProductJson, SubscriptionType } from "scripts/generateProductsJson";

import isValidProductName from "@helpers/data/isValidProductName";
import { KeyIngredient } from "@helpers/data/keyIngredients";
import { getProductDataByName, ProductImages } from "@helpers/getProductDataByName";
import { getProductDataById } from "@helpers/getProductDataById";

export const PRODUCTS_ORDER: Partial<ProductSlug>[] = [
  "probiotic-chews",
  "calming-chews",
  "joint-care-chews",
  "skin-coat-chews",
];

export const productNames = [
  "Calming Chews",
  "Joint Care Chews",
  "Probiotic Chews",
  "Skin & Coat Chews",
  "Anti-Anxiety Calming Bed",
  "Safe Buckle™ Dog Seatbelt",
] as const;

export const productSlugByProductNameMap = {
  "Calming Chews": "calming-chews",
  "Joint Care Chews": "joint-care-chews",
  "Probiotic Chews": "probiotic-chews",
  "Skin & Coat Chews": "skin-coat-chews",
  "Anti-Anxiety Calming Bed": "anti-anxiety-calming-bed",
  "Safe Buckle™ Dog Seatbelt": "safe-buckle-dog-seatbelt",
} as const;

export type ProductName = (typeof productNames)[number];
export type ProductSlug = (typeof productSlugByProductNameMap)[ProductName];

export type Product = {
  id: string;
  price: number;
  oldPrice: number;
  sku: string;
  name: ProductName;
  secondName: string;
  slug: ProductSlug;
  images: ProductImages;
  color: string;
  keyIngredients: KeyIngredient[];
  description: string;
  qualities: string[];
  imageIngredients: string[];
  fullIngredients: {
    title: string;
    ingredients?: string[];
    list?: string[];
  }[];
  frequentlyBrought: string;
  realResults: RealResultsProps;
  faq: FaqSectionData[];
  type?: string;
};

export type BedProduct = {
  color: string;
  id: string;
  name: string;
  price: number;
  size: string;
  sku: string;
  type: string;
  image: string;
  frequentlyBrought: string;
  slug: string;
};

export const getProductById = (id: Product["id"]) => {
  return (products as ProductJson)[id];
};

export default (
  productName: ProductName,
  subscriptionType: SubscriptionType | undefined,
  packageSize: number | undefined
): Product | undefined => {
  if (!isValidProductName(productName)) return undefined;

  const product = Object.values(products as ProductJson).find((productEl) => {
    return (
      productEl.name === productName &&
      productEl.subscriptionType === subscriptionType &&
      productEl.packageSize === packageSize
    );
  }) as Product | BedProduct;

  const onceProduct = Object.values(products as ProductJson).find((productEl) => {
    return (
      productEl.name === productName &&
      productEl.subscriptionType === "NONE" &&
      productEl.packageSize === packageSize
    );
  }) as Product | BedProduct;

  const productData = {
    ...getProductDataByName(productName),
    ...getProductDataById(product.id),
  };

  return {
    ...productData,
    id: product.id,
    price: product.price,
    sku: product.sku,
    oldPrice: onceProduct?.price || productData.oldPrice || 0,
    name: productName,
    type: product.type,
  };
};
