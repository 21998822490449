/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes } from "react";

import Icon from "@components/common/Icon";

import { formatTimeInMs } from "@utils/formatTimeInMs";

interface CheckoutPaymentBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  time: number;
}

const CheckoutPaymentBtn = ({ type = "button", text, time, ...props }: CheckoutPaymentBtnProps) => {
  const { hours, minutes, seconds } = formatTimeInMs(time);

  return (
    <button className="checkout-payment-btn" type={type} {...props}>
      <div className="checkout-payment-btn__decoration">
        <span className="checkout-payment-btn__text">{text}</span>
        <span className="checkout-payment-btn__timer">
          {hours}:{minutes}:{seconds}
        </span>
      </div>

      <div className="checkout-payment-btn__icon">
        <Icon name="sm-arrow-right" />
      </div>
    </button>
  );
};

export default CheckoutPaymentBtn;
