import React, { useEffect, useState, Dispatch, SetStateAction } from "react";

import { formatTimeInMs } from "@utils/formatTimeInMs";

import Icon, { IconName } from "@components/common/Icon";
import classNames from "classnames";

export const defaultTime = (18 * 3600 + 32 * 60 + 12) * 1000;

interface TimerProps {
  time: number;
  setTime: Dispatch<SetStateAction<number>>;

  initialTime?: number;

  icon?: IconName;
  color?: "orange" | "green";
}

const Timer = ({
  time,
  setTime,
  initialTime = defaultTime,
  icon = "paw",
  color = "orange",
}: TimerProps) => {
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time <= 0 ? initialTime : time - 1000);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [initialTime, time, setTime]);

  const { hours, minutes, seconds } = formatTimeInMs(time);

  return (
    <div className={classNames("timer", color)}>
      <div className="timer__text-wrapper">
        <Icon className="timer__icon" name={icon} />
        <p className="timer__paragraph">Free shipping on all orders</p>
      </div>

      <div className="timer__time-wrapper">
        <p className="timer__paragraph  --time">Ends in</p>

        <div className="timer__time">
          <span className="timer__number">{hours}</span>
          <span className="timer__separator">:</span>
          <span className="timer__number">{minutes}</span>
          <span className="timer__separator">:</span>
          <span className="timer__number">{seconds}</span>
        </div>
      </div>
    </div>
  );
};

export default Timer;
