import React from "react";

import { CartItem } from "@typings/cart";
import classNames from "classnames";
import { isChewProduct } from "@utils/product";

interface CartItemImgProps {
  cartItem: CartItem;
  redirectToProductPage: () => void;
}

const CartItemImg = ({ cartItem, redirectToProductPage }: CartItemImgProps) => {
  const { product } = cartItem;

  return (
    <button
      type="button"
      onClick={redirectToProductPage}
      className={classNames("cart-item__img", { "--is-chew": isChewProduct(product) })}
    >
      <img className="cart-item__img-content" src={product.image} alt={product.name} />
    </button>
  );
};

export default CartItemImg;
