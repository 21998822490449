import React, { FC } from "react";
import { useFormikContext } from "formik";

import type { CheckoutFormValues } from "./CheckoutFormContainer";

interface Props {
  error: string;
  formType: "information" | "payment";
  isShowErrors: boolean;
}

const FormErrors: FC<Props> = ({ error, formType, isShowErrors }) => {
  const { errors, submitCount } = useFormikContext<CheckoutFormValues>();

  const preparedErrors = Object.keys(errors).map((key) => {
    let message = (errors as { [key: string]: any })[key];

    if (typeof message === "object") {
      message = message[Object.keys(message)[0]];
    }

    return { key, message };
  });

  if (!isShowErrors && submitCount < 1) return null;
  if (submitCount < 1 && formType === "payment") return null;

  const filteredErrors =
    formType === "information"
      ? preparedErrors?.filter((e) => !["cardCode", "cardNumber", "cardExpiry"].includes(e.key))
      : preparedErrors;

  const allErrors = error ? [{ message: error, key: "" }, ...filteredErrors] : filteredErrors;

  return (
    <div className="errors">
      {allErrors.map((errorItem) => (
        <div className="error-message" key={errorItem.key || errorItem.message}>
          {errorItem.message}
        </div>
      ))}
    </div>
  );
};

export default FormErrors;
