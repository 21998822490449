import { getProductDataByName } from "@helpers/getProductDataByName";
import { CartItem } from "@typings/cart";

const FreeBedNotifierCartItem: CartItem = {
  quantity: 1,
  isFree: true,
  product: {
    ...getProductDataByName("Anti-Anxiety Calming Bed"),
    image: "/images/beds/beige.png",
    name: "FREE Dog Bed Unlocked!",
    additionalDescription: "Click to choose the bed",
  },
};

export const FREE_NOTIFIERS_MAP = {
  BED: FreeBedNotifierCartItem,
};
