/* eslint-disable react/no-unescaped-entities */
import React from "react";

import PaymentMethodsGallery from "@components/common/image/gallery/payment-methods/PaymentMethodsGallery";
import SecurityCertificationsGallery from "@components/common/image/gallery/security-certifications/SecurityCertificationsGallery";

import CheckoutGuaranteesItem from "./item/CheckoutGuaranteesItem";

const CheckoutGuarantees = () => {
  return (
    <div className="checkout-guarantees">
      <CheckoutGuaranteesItem icon="shield-check-mark" title="Safe Payment Options">
        <PaymentMethodsGallery />
      </CheckoutGuaranteesItem>

      <CheckoutGuaranteesItem icon="shield-check-mark" title="Security certifications">
        <SecurityCertificationsGallery />
      </CheckoutGuaranteesItem>

      <CheckoutGuaranteesItem icon="shield-check-mark" title="MEDIPUPS payment protection">
        Your Security, Our Priority. Shop with peace of mind. SSL Encrypted Transactions, 24/7 Support & Payment Protection Guarantee.
      </CheckoutGuaranteesItem>

      <CheckoutGuaranteesItem icon="lock" title="Secure Checkout">
        Your Data, Your Rights! Rest assured, we keep your info secure. No Third-Party Sharing & Commitment to Privacy Standards.
      </CheckoutGuaranteesItem>
    </div>
  );
};

export default CheckoutGuarantees;
