/* eslint-disable react/jsx-props-no-spreading */
import React, { ButtonHTMLAttributes, FC } from "react";
import classNames from "classnames";

import Icon, { IconName } from "@components/common/Icon";
import Spinner from "@components/common/Spinner";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  icon?: IconName;
  iconClassName?: string;
  loading?: boolean;
  bigness?: "md" | "xl";
  color?:
    | "primary"
    | "primary-light"
    | "primary-dark"
    | "secondary"
    | "secondary-link"
    | "link"
    | "transparent"
    | "edit"
    | "black"
    | "yellow"
    | "orange-link";
}

const Button: FC<ButtonProps> = ({
  className,
  color = "primary",
  bigness = "md",
  icon,
  children,
  iconClassName = "button-icon",
  loading = false,
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNames(className, "btn", {
        [`btn-${color}`]: color,
        [`bigness-${bigness}`]: bigness,
        "with-icon": !!icon,
      })}
      {...props}
    >
      <span>{!loading ? children : <Spinner />}</span>
      {icon && <Icon name={icon} className={iconClassName} />}
    </button>
  );
};

export default Button;
