import React, { FC } from "react";

import classnames from "classnames";

import InputField from "@components/common/InputField";

type Props = {
  autoFocus?: boolean;
  className?: string;
};

const PhoneInput: FC<Props> = ({ autoFocus, className }) => {
  return (
    <div className={classnames("input-group", className)}>
      <InputField
        label="Phone (optional)"
        name="phoneNumber"
        mask="+1 (999) 999-9999"
        maskChar=""
        autoFocus={autoFocus}
      />
    </div>
  );
};

export default PhoneInput;
