/* eslint-disable import/prefer-default-export */
import getProduct, { BedProduct, Product, productNames } from "@helpers/getProduct";

import { kebabCase } from "lodash";

import products from "@data/products.json";

interface GetProducts {
  (isFreeGift?: boolean, isBelt?: boolean): (Product | BedProduct | undefined)[];
}

export const getProducts: GetProducts = (isFreeGift = false, isBelt = false) => {
  if (isBelt) {
    const beltProd: BedProduct[] = Object.values(products).filter(
      ({ type }) => type === "SEATBELT"
    ) as BedProduct[];

    return beltProd.map((item) => ({
      ...item,
      image: `/images/belt/${item.color}.png`,
      frequentlyBrought: {
        id: "j0wYMmJ4a6Hdp1G8rJKM",
        name: "Calming Chews",
        image: "/images/calming/quantity/1.png",
      },
    }));
  }

  if (isFreeGift) {
    const SKUs = ["monthlygifts", "nutribooklet", "Paw/Soother/30Free"];
    const freeGifts = Object.values(products).filter(({ sku }) =>
      SKUs.includes(sku)
    ) as BedProduct[];

    return freeGifts.map((item) => ({
      ...item,
      image: `/images/gifts/${item.id}.png`,
      frequentlyBrought: {
        id: "j0wYMmJ4a6Hdp1G8rJKM",
        name: "Calming Chews",
        image: "/images/calming/quantity/1.png",
      },
    }));
  }

  return productNames.map((name) => getProduct(name, "MONTHLY", 1)).filter((name) => name);
};
