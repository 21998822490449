/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useRef, useState } from "react";

import { PageProps, navigate } from "gatsby";
import { v4 as uuidv4 } from "uuid";

import * as yup from "yup";

import { Form, FormikProvider, useFormik } from "formik";

import useLocalStorageState from "use-local-storage-state";
import axios from "axios";

import Button from "@components/common/Button";
import Spinner from "@components/common/Spinner";

import CheckoutInformationForm from "@components/checkout/CheckoutInformationForm";
import FormTypeBreadcrumb from "@components/checkout/FormTypeBreadcrumb";
import CheckoutPaymentForm, { ShippingType } from "@components/checkout/CheckoutPaymentForm";
import FormErrors from "@components/checkout/FormErrors";
import { isMedBoxProduct } from "@utils/product";
import type { CheckoutFormType, CouponState } from "@pages/checkout";

import scrollTo from "@helpers/scrollTo";
import { Product } from "@helpers/getProduct";

import { useSelector } from "react-redux";
import { selectCartItems } from "@store/cart";
import { trackPlacedOrder } from "@analytics/klaviyo";
import { gaAddPaymentInfo, gaAddShippingInfo, gaPurchase } from "@analytics/google";
import { CartItem } from "@typings/cart";

import Timer, { defaultTime } from "@components/common/timer/Timer";
import PaymentMethodsGallery from "@components/common/image/gallery/payment-methods/PaymentMethodsGallery";
import SecurityCertificationsGallery from "@components/common/image/gallery/security-certifications/SecurityCertificationsGallery";

import CheckoutLeave from "@components/modal/checkout/leave/CheckoutLeave";
import CheckoutPaymentBtn from "./buttons/payment/CheckoutPaymentBtn";
import validationSchema from "./validationSchema";
import CheckoutGuarantees from "./guarantees/CheckoutGuarantees";

export const freeGiftItem: CartItem = {
  isFree: true,
  quantity: 1,
  product: {
    deliveryFrequency: "NONE",
    id: "pg1qB1vq8gIY5KY5dZAV",
    quantity: 1,
    price: 0,
    oldPrice: 0,
    secondName: "",
    images: {
      mainImage: "/images/dog-bed.png",
      mainProductImage: "",
      additionalImages: [],
      quantityImages: [],
      ingredients: [],
      benefitsImage: "",
      feedingGuide: "",
    },
    description:
      "You will receive an email after purchase to confirm the desired size of your dog bed (S - XL)",
    qualities: [],
    imageIngredients: [],
    frequentlyBrought: "",
    faq: [],
    fullIngredients: [],
    realResults: null,
    color: "",
    keyIngredients: [],
    slug: "premium-cuddler-bed",
    name: "Anti-Anxiety Calming Bed",
    sku: "free-cuddler-bed",
  },
};

export const medipupsPlus: CartItem = {
  isFree: true,
  quantity: 1,
  product: {
    deliveryFrequency: "NONE",
    id: "8ragLjlOzgF2m4adGAJ1",
    quantity: 1,
    price: 0,
    oldPrice: 0,
    secondName: "",
    type: "MEDIPUPSPLUS",
    image: "/images/medipups-plus.png",
    images: {
      mainImage: "/images/medipups-plus.png",
      mainProductImage: "",
      additionalImages: [],
      quantityImages: [],
      ingredients: [],
      benefitsImage: "",
      feedingGuide: "",
    },
    description: "",
    qualities: [],
    imageIngredients: [],
    frequentlyBrought: "",
    faq: [],
    fullIngredients: [],
    realResults: null,
    color: "",
    keyIngredients: [],
    slug: "medipups-plus",
    name: "Medipups Plus",
    sku: "medipupsplus",
  },
};

export interface CheckoutFormValues {
  email: string;
  firstName: string;
  lastName: string;
  streetAddress?: string;
  streetAddress2?: string;
  city: string;
  zipCode: string;
  state: string;
  phoneNumber?: string;
  country: string;
  isDifferentAddress: boolean;
  diffStreetAddress?: string;
  diffStreetAddress2?: string;
  diffCity: string;
  diffZipCode: string;
  diffState: string;
  diffCountry: string;
  cardNumber: string;
  cardExpiry: string;
  cardCode: string;
}

interface Props {
  location: PageProps<object, object, any>["location"];
  orderId: string;
  coupon: CouponState;
  setCoupon: Dispatch<SetStateAction<CouponState>>;
  shipping: ShippingType;
  order: boolean;
  setOrder: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
  handleChangeShipping: (value: any) => void;
  orderLoading: boolean;
  setOrderLoading: Dispatch<SetStateAction<boolean>>;
  formType: CheckoutFormType;
  setFormType: (newFormType: CheckoutFormType) => void;
  cartTotal: number;
  cartSubTotal: number;
  shippingPrice: string;
  oldTotalCart: number;
  time: number;
  setTime: Dispatch<SetStateAction<number>>;
}

type OrderValues = {
  orderId: string;
  shippingAddress: {
    addressLine1: string;
    city: string;
    zip: string;
    country: string;
    state: string;
  };
  products: Product[];
  customer: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    billingAddress: {
      addressLine1: string;
      city: string;
      country: string;
      state: string;
      zip: string;
    };
  };
  creditCard: {
    cardNumber: string;
    cardCode: string;
    expMonth: number;
    expYear: number;
  };
  shippingMethod: string;
  coupon: string;
};

const CheckoutFormContainer: FC<Props> = ({
  location,
  orderId,
  formType,
  setFormType,
  coupon,
  orderLoading,
  order,
  setOrder,
  setEmail,
  shipping,
  setCoupon,
  handleChangeShipping,
  setOrderLoading,
  cartTotal,
  cartSubTotal,
  shippingPrice,
  oldTotalCart,
  time,
  setTime,
}) => {
  const [orderValues, setOrderValues] = useState<any>(undefined); // temporary OrderValues | undefined
  const [focus, setFocus] = useState<string | undefined>(undefined);
  const [checkoutError, setCheckoutError] = useState<string>("");
  const upsellOrderId = useRef(uuidv4());

  const cartItems = useSelector(selectCartItems);

  const [gclid] = useLocalStorageState("mp-gclid");

  const isWizardFlow = location.search.includes("wizard");
  const bedItemsInCart = cartItems.find((item) => !item.isFree && item.product.type === "BED");
  const beltItemsInCart = cartItems.find(
    (item) => !item.isFree && item.product.type === "SEATBELT"
  );

  const handleSubmitForm = async (values: CheckoutFormValues): Promise<void> => {
    const submittedCartItems = [...cartItems];

    setOrderLoading(true);
    setCheckoutError("");

    const {
      cardCode,
      cardNumber,
      cardExpiry,
      firstName,
      lastName,
      email,
      phoneNumber,
      streetAddress,
      streetAddress2,
      city,
      state,
      zipCode,
      diffStreetAddress,
      diffStreetAddress2,
      diffCity,
      diffState,
      diffZipCode,
      isDifferentAddress,
    } = values;

    const [expMonth, expYear] = cardExpiry.split("/");

    const isFreeGiftConditionSatisfied =
      process.env.GATSBY_ENABLE_FREE_GIFT &&
      cartItems.some(
        ({ product }) =>
          isMedBoxProduct(product) &&
          (product.subscriptionType === "EVERY_6_MONTHS" ||
            product.subscriptionType === "EVERY_12_MONTHS")
      ) &&
      submittedCartItems.every(({ product }) => product.slug !== "anti-anxiety-calming-bed");

    // const cartItemsToSend = isFreeGiftConditionSatisfied
    //     ? cartItems.concat(freeGiftItem)
    //     : cartItems;

    // if (isFreeGiftConditionSatisfied) {
    //   submittedCartItems.push(freeGiftItem);
    // }

    if ((bedItemsInCart || beltItemsInCart) && isWizardFlow === false) {
      if (
        submittedCartItems.findIndex(
          (cartItem) => cartItem.product.id === medipupsPlus.product.id
        ) === -1
      ) {
        submittedCartItems.push(medipupsPlus);
      }
    }

    const products = submittedCartItems.map((cartItem) => ({
      id: cartItem.product.id,
      quantity: cartItem.quantity,
    }));

    const billingAddress = {
      addressLine1: (isDifferentAddress ? diffStreetAddress : streetAddress) as string,
      addressLine2: (isDifferentAddress ? diffStreetAddress2 : streetAddress2) as string,
      city: isDifferentAddress ? diffCity : city,
      zip: isDifferentAddress ? diffZipCode : zipCode,
      country: "US",
      state: isDifferentAddress ? diffState : state,
    };

    const shippingAddress = {
      addressLine1: streetAddress as string,
      addressLine2: streetAddress2 as string,
      city,
      zip: zipCode,
      country: "US",
      state,
    };

    const customer = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      phone: `${phoneNumber}`.trim(),
      billingAddress,
    };

    const variables = {
      orderId,
      shippingAddress,
      products,
      customer,
      creditCard: {
        cardNumber: cardNumber.replaceAll(" ", ""),
        cardCode,
        expMonth: parseInt(expMonth, 10),
        expYear: parseInt(expYear, 10),
      },
      shippingMethod: shipping,
      coupon: coupon.isApplied ? coupon.value : "",
      metadata: {} as any,
    };

    if (gclid) {
      variables.metadata.adwords_click = gclid;
    }

    if (isWizardFlow) {
      variables.metadata.dogName = location?.state.wizardFormValues?.name;
      variables.metadata.dogGender = location?.state.wizardFormValues?.gender;
      variables.metadata.dogWeight = location?.state.wizardFormValues?.weight;
      variables.metadata.dogBreed = location?.state.wizardFormValues?.breed;
      variables.metadata.dogMixed = location?.state.wizardFormValues?.mixed;
      variables.metadata.dogBirthday = location?.state.wizardFormValues?.birthday;
    }

    setOrderValues(variables);

    try {
      if (coupon.isApplied) {
        const { data } = await axios.post(`${window.location.origin}/api/estimate`, {
          coupon: coupon.value,
          shippingMethod: shipping,
          products,
          email,
        });

        if (data?.error_code || data?.coupons?.invalid?.length) {
          setCoupon({
            value: "",
            discount: 0,
            isApplied: false,
            message: { title: "Invalid coupon", type: "ERROR" },
          });

          throw new Error("Invalid Coupon.");
        }
      }

      const { data } = await axios.post(`${window.location.origin}/api/checkout`, variables);

      if (data?.message) {
        throw new Error(data.message);
      }

      if (data) {
        trackPlacedOrder({
          cartItems: submittedCartItems,
          orderId,
          email,
          userInfo: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            city,
            country: "US",
            phoneNumber,
            region: state,
            zip: zipCode,
          },
          billingAddress,
          shippingAddress,
          coupon: coupon.isApplied ? coupon.value : "",
          discount: coupon.discount,
          shippingMethod: shipping,
        });

        const gaUserData = {
          user_id: data.customer_id || null,
          phone_number: customer.phone.replace(/\D/g, ""),
          email_address: customer.email,
          address: {
            city,
            address: streetAddress,
            address2: streetAddress2,
            state,
            country: "US",
            postal_code: zipCode,
            first_name: customer.firstName,
            last_name: customer.lastName,
          },
        };

        gaAddPaymentInfo({
          cartItems: submittedCartItems,
          ecommerce: {
            payment_type: "Credit Card",
            value: data.amount,
            currency: "USD",
            coupon: coupon.isApplied ? coupon.value : null,
          },
          user_data: gaUserData,
        });

        gaPurchase({
          cartItems: submittedCartItems,
          ecommerce: {
            transaction_id: data.transaction_id || null,
            value: data.amount,
            shipping: shipping === "FREE" ? 0 : 14.99,
            currency: "USD",
            coupon: coupon.isApplied ? coupon.value : null,
          },
          user_data: gaUserData,
        });
      }

      setOrder(!!data);
      // eslint-disable-next-line no-empty
    } catch (error: any) {
      if (error?.response?.data?.includes("quantity must be")) {
        setCheckoutError("The quantity of one unit of the product must not exceed 20");
      } else if (!error?.message?.includes("Invalid Coupon")) {
        setCheckoutError(error?.response?.data || error?.message);
      }
    } finally {
      setOrderLoading(false);
    }
  };

  const formik = useFormik<CheckoutFormValues>({
    onSubmit: handleSubmitForm,
    validateOnMount: false,
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      streetAddress: "",
      streetAddress2: "",
      city: "",
      zipCode: "",
      state: "",
      phoneNumber: "",
      country: "United States (US)", // For inputs, but we'll always send "US" as country
      isDifferentAddress: false,
      diffCity: "",
      diffCountry: "United States (US)", // For inputs, but we'll always send "US" as diffCountry
      diffState: "",
      diffStreetAddress: "",
      diffStreetAddress2: "",
      diffZipCode: "",
      cardNumber: "",
      cardExpiry: "",
      cardCode: "",
    },
    validationSchema,
  });

  const { handleSubmit, values, validateForm } = formik;

  const { email } = values;

  useEffect(() => {
    yup
      .string()
      .email("Invalid email")
      .validate(email)
      .then(() => {
        setEmail(email);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (order && values) {
      void navigate("/thankyou", {
        state: {
          values,
          cartItems,
          coupon,
          cartTotal,
          cartSubTotal: oldTotalCart,
          shippingPrice,
          orderValues,
          upsellOrderId: upsellOrderId.current,
        },
      });
    }
  }, [cartItems, cartSubTotal, cartTotal, coupon, order, orderValues, shippingPrice, values]);

  useEffect(() => {
    void validateForm(values).then((errors) => {
      const filteredErrors = Object.keys(errors).filter(
        (e) => !["cardCode", "cardNumber", "cardExpiry"].includes(e)
      );

      if (formType === "payment" && filteredErrors.length > 0) {
        setFormType("information");
      }
    });
  }, []);

  const [isShowErrors, setIsShowErrors] = useState(false);

  const handleChangeFormType = (isBreadcrumb: boolean): void => {
    scrollTo("top");

    void validateForm(values).then((errors) => {
      if (
        formType === "information" &&
        !!Object.keys(errors || {}).filter(
          (e) => !["cardCode", "cardNumber", "cardExpiry"].includes(e)
        ).length
      ) {
        if (!isBreadcrumb) {
          setIsShowErrors(true);
        }

        return null;
      }

      setIsShowErrors(false);

      if (isBreadcrumb === false) {
        const isDiffAddress = values.isDifferentAddress;
        const customer = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phoneNumber,
          billingAddress: {
            firstName: values.firstName,
            lastName: values.lastName,
            addressLine1: isDiffAddress ? values.diffStreetAddress : values.streetAddress,
            addressLine2: isDiffAddress ? values.diffStreetAddress2 : values.streetAddress2,
            city: isDiffAddress ? values.diffCity : values.city,
            zip: isDiffAddress ? values.diffZipCode : values.zipCode,
            country: "US",
            state: isDiffAddress ? values.diffState : values.state,
            phone: values.phoneNumber,
          },
        };

        try {
          void axios.post(`${window.location.origin}/api/customer`, customer);
          // eslint-disable-next-line no-empty
        } catch (err) {}

        gaAddShippingInfo({
          cartItems,
          ecommerce: {
            value: cartSubTotal,
            currency: "USD",
            shipping_tier: "FREE",
            coupon: coupon.value,
          },
          user_data: {
            phone_number: values?.phoneNumber?.replace(/\D/g, ""),
            email_address: values.email,
            address: {
              city: values.city,
              address: values.streetAddress,
              address2: values.streetAddress2,
              state: values.state,
              country: "US",
              postal_code: values.zipCode,
              first_name: values.firstName,
              last_name: values.lastName,
            },
          },
        });
      }

      return setFormType(formType === "information" ? "payment" : "information");
    });
  };

  if (orderLoading) {
    return <Spinner />;
  }

  return (
    <div className="checkout-form-container">
      <FormikProvider value={formik}>
        <FormTypeBreadcrumb formType={formType} setFormType={() => handleChangeFormType(true)} />

        <Timer time={time} setTime={setTime} icon="circle-check-mark" color="green" />

        <FormErrors error={checkoutError} formType={formType} isShowErrors={isShowErrors} />

        <Form onSubmit={handleSubmit} className="checkout-form">
          <CheckoutInformationForm
            cartItems={cartItems}
            formType={formType}
            focus={focus}
            setFocus={setFocus}
            orderId={orderId}
          />

          <CheckoutPaymentForm
            formType={formType}
            setFormType={setFormType}
            shipping={shipping}
            setFocus={setFocus}
            handleChangeShipping={handleChangeShipping}
          />

          {formType === "information" ? (
            <CheckoutPaymentBtn
              text="Continue to payment"
              time={time}
              onClick={() => handleChangeFormType(false)}
            />
          ) : (
            <CheckoutPaymentBtn
              type="submit"
              text="Complete order"
              time={time}
              disabled={orderLoading}
              style={{ marginTop: "24px" }}
            />
          )}

          <CheckoutGuarantees />

          <CheckoutLeave time={time} location={location} />
        </Form>
      </FormikProvider>
    </div>
  );
};

export default CheckoutFormContainer;
