import { map, mapKeys, omit, uniq } from "lodash";
import { CartItem } from "@typings/cart";
import { ShippingType } from "@components/checkout/CheckoutPaymentForm";
import { BASE_URL } from "@constants/common";
import { Product } from "@helpers/getProduct";

import { KlaviyoEvent } from "./types/KlaviyoEvent";
import { UserInfo } from "./types/UserInfo";
import { Address } from "./types/Address";
import { transformCartItemsToKlaviyo } from "./utils/transformCartItemsToKlaviyo";
import { getSubtotal, getTotal } from "./utils/getTotals";
import { transformCartItemsToUrl } from "./utils/transformCartItemsToUrl";
import { getShippingCost } from "./utils/getShippingCost";
import { transformAddressToKlaviyo } from "./utils/transformAddressToKlaviyo";

/* ===========
  Types
  ======== */
interface TrackStartedCheckoutParams {
  email: string;
  userInfo?: UserInfo;
  orderId: string;
  cartItems: CartItem[];
}

interface TrackPlacedOrderParams {
  email: string;
  userInfo: UserInfo;
  cartItems: CartItem[];
  orderId: string;
  billingAddress: Address;
  shippingAddress: Address;
  coupon: string | undefined;
  discount: number;
  shippingMethod: ShippingType;
}

interface TrackOrderedProductParams {
  cartItem: CartItem;
  orderId: string;
}

declare global {
  interface Window {
    _learnq: any;
    dataLayer: any;
  }
}

/* =============
  Analytic Functions
  ============ */
const dataLayer = typeof window !== "undefined" && window.dataLayer;
const learnq = typeof window !== "undefined" && window._learnq;

export const isIdentified = (): string | undefined => learnq?.isIdentified?.();

export const identify = (email: string, userInfo?: UserInfo): void => {
  const info = userInfo
    ? mapKeys(
        {
          ...userInfo,
          first_name: userInfo.firstName,
          last_name: userInfo.lastName,
        },
        (val, key) => `$${key}`
      )
    : {};

  learnq?.push([
    "identify",
    {
      $email: email,
      ...info,
    },
  ]);
};

export const track = (event: KlaviyoEvent, data: any): void => {
  console.info(`Tracking ${event}: `, data);

  dataLayer?.push({ event, ...data });

  learnq?.push(["track", event, data]);
};

export const trackAddedToCart = (cartItems: CartItem[], newCartItems: CartItem[]): void => {
  if (!isIdentified()) return;

  const cartItemsURL = transformCartItemsToUrl(cartItems);
  const klaviyoCartItems = transformCartItemsToKlaviyo(cartItems);
  const klaviyoNewCartItems = transformCartItemsToKlaviyo(newCartItems);

  const cartItemsNames = uniq(map(cartItems, "product.name"));

  const value = getSubtotal(cartItems);

  klaviyoNewCartItems.forEach((klaviyoNewCartItem) => {
    track("Added to Cart", {
      ...mapKeys(klaviyoNewCartItem, (_, key) => `AddedItem${key}`),
      $value: value,
      ItemNames: cartItemsNames,
      ItemCount: klaviyoCartItems.length,
      CartUrl: cartItemsURL,
    });
  });
};

export const trackStartedCheckout = ({
  email,
  userInfo,
  orderId,
  cartItems,
}: TrackStartedCheckoutParams): void => {
  identify(email, userInfo);

  const cartItemsURL = transformCartItemsToUrl(cartItems);
  const klaviyoCartItems = transformCartItemsToKlaviyo(cartItems);

  const cartItemsNames = uniq(map(cartItems, "product.name"));

  const value = getSubtotal(cartItems);

  const data = {
    $event_id: orderId,
    $value: value,
    ItemNames: cartItemsNames,
    CheckoutURL: `${BASE_URL}/checkout`,
    CurrencySymbol: "&#36",
    Currency: "USD",
    extra: {
      Items: klaviyoCartItems,
      SubTotal: value,
    },
    CartUrl: cartItemsURL,
  };

  track("Started Checkout", data);
};

export const trackOrderedProduct = ({ cartItem, orderId }: TrackOrderedProductParams): void => {
  const [klaviyoCartItem] = transformCartItemsToKlaviyo([cartItem]);

  const value = getSubtotal([cartItem]);

  const data = {
    ...klaviyoCartItem,
    $event_id: `${orderId}_${klaviyoCartItem.SKU}`,
    $value: value,
    OrderId: orderId,
    time: new Date().toISOString(),
  };

  track("Ordered Product", data);
};

export const trackPlacedOrder = ({
  cartItems,
  orderId,
  billingAddress,
  shippingAddress,
  userInfo,
  coupon,
  discount,
  email,
  shippingMethod,
}: TrackPlacedOrderParams): void => {
  identify(email, userInfo);

  if (!isIdentified()) return;

  const value = getTotal(cartItems, discount, shippingMethod);

  const klaviyoCartItems = transformCartItemsToKlaviyo(cartItems);
  const cartItemsNames = uniq(map(cartItems, "product.name"));

  const phone = userInfo.phoneNumber!;

  const data = {
    $event_id: orderId,
    $value: value,
    $currency_code: "USD",
    time: new Date().toISOString(),
    ItemNames: cartItemsNames,
    IsDiscounted: !!discount,
    UsedCoupon: !!coupon,
    DiscountCode: coupon,
    DiscountValue: discount,
    ShippingMethods: shippingMethod,
    extra: {
      OrderId: orderId,
      TotalShipping: getShippingCost(shippingMethod),
      TotalDiscount: discount,
      BillingAddress: transformAddressToKlaviyo({
        customer: userInfo,
        address: billingAddress,
        phone,
      }),
      ShippingAddress: transformAddressToKlaviyo({
        customer: userInfo,
        address: shippingAddress,
        phone,
      }),
      Items: klaviyoCartItems,
    },
  };

  track("Placed Order", data);

  cartItems.forEach((cartItem) =>
    trackOrderedProduct({
      cartItem,
      orderId,
    })
  );
};

export const trackViewedProduct = (product: Product): void => {
  if (!isIdentified()) return;

  const [item] = transformCartItemsToKlaviyo([{ product, quantity: 1 }]);

  const data = { ...omit(item, ["LineTotal", "Quantity", "PackageSize"]) };

  track("Viewed Product", data);
};
