import { ProductDeliveryFrequency } from "@typings/product";

export type ProductData = {
  oldPrice: number;
  image: string;
  deliveryFrequency: ProductDeliveryFrequency;
};

const productData: Record<string, ProductData> = {
  djowjyv60qCY0X9MV4GG: {
    oldPrice: 69,
    deliveryFrequency: "MONTHLY",
    image: "/images/calming/quantity/1.png?v=2",
  },
  "1RA4RZ8jRksnQpV0ZyGw": {
    oldPrice: 209,
    deliveryFrequency: "MONTHLY",
    image: "/images/calming/quantity/2.png?v=2",
  },
  pJMzg8GwJzUVo9OkXnro: {
    oldPrice: 394,
    deliveryFrequency: "MONTHLY",
    image: "/images/calming/quantity/4.png?v=2",
  },
};

export const getProductDataById = (id: string): ProductData => {
  return productData[id];
};
