import React, { FC, useEffect, useState } from "react";

import classNames from "classnames";
import useLocalStorageState from "use-local-storage-state";

import Button from "@components/common/Button";
import { PageProps } from "gatsby";

const Banner: FC<{ location: PageProps["location"] }> = ({ location }) => {
  const isBedPage = location.pathname.includes("anti-anxiety-calming-bed");
  const isBoxPage = location.pathname.includes("box");
  const isBeltPage = location.pathname.includes("safe-buckle-dog-seatbelt");

  const bannerText = process.env.GATSBY_ANNOUNCEMENT_BANNER;

  const [bannerIsOpen, setBannerIsOpen] = useLocalStorageState<boolean>("isOpenPromoCodeBanner", {
    defaultValue: !!bannerText,
  });
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    setHasWindow(true);
  }, []);

  const handleCloseBanner = (): void => {
    setBannerIsOpen(!bannerIsOpen);
  };

  return (
    <div className="banner-container">
      <div
        className={classNames("banner", {
          showed: !!bannerText && bannerIsOpen && hasWindow && !isBeltPage,
        })}
      >
        <div
          className="banner-info"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: bannerText || "",
          }}
        />

        <Button
          name="close-banner"
          icon="cross"
          color="transparent"
          onClick={handleCloseBanner}
          aria-label="close-banner"
        />
      </div>
    </div>
  );
};

export default Banner;
