import React, { FC, useState } from "react";

import axios from "axios";
import * as yup from "yup";

import { useFormik } from "formik";
import { Link } from "gatsby";

import Icon from "@components/common/Icon";
import Spinner from "@components/common/Spinner";

import FacebookIcon from "@assets/svg/social/facebook.svg";
import InstagramIcon from "@assets/svg/social/instagram.svg";
import TikTokIcon from "@assets/svg/social/tik-tok.svg";

interface FormValues {
  email: string;
}

const footerLinksSections = [
  {
    sectionTitle: "Company",
    links: [
      {
        title: "Our Story",
        to: "/about-us",
      },
      {
        title: "Order Tracking",
        to: "/track-order",
      },
      {
        title: "Contact Us",
        to: "/contact-us",
      },
      {
        title: "Products",
        to: "/shop",
      },
    ],
  },
  {
    sectionTitle: "Information",
    links: [
      {
        title: "Refund Policy",
        to: "/faqs/#refund-policy",
      },
      {
        title: "Shipping & Handling",
        to: "/faqs/#shipping-and-delivery",
      },
      {
        title: "FAQs",
        to: "/faqs",
      },
    ],
  },
];

const validationSchema = yup
  .object()
  .shape({ email: yup.string().email("Invalid email address").required("Email is required") });

const Footer: FC = () => {
  const initialValues: FormValues = { email: "" };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    setIsLoading(true);

    await axios
      .get(`${window.location.origin}/api/subscribe/${values.email}`)
      .then(() => {
        setIsLoading(false);
        setIsSubmitted(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error.response);
      });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <footer className="footer-container">
      <div className="main-footer">
        <div className="company-info">
          <Icon width={152} height={24} name="logo" className="logo" />
          <p className="description">Medipups is a company built for pet owners, by pet owners.</p>
          <p className="contacts">
            <span>support@medipups.com</span>
            <span>+1 (888) 507-3139</span>
          </p>
        </div>

        <div className="newsletter">
          <p className="title">Newsletter</p>
          <p className="description">
            Join Our Mailing List For Awesome Updates & Unlock Special Discounts!
          </p>
          <form onSubmit={formik.handleSubmit} className="input-group-form">
            <input
              type="text"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Your Email Address"
              disabled={isSubmitted}
            />
            <button type="submit" disabled={isSubmitted}>
              <div className="icon">
                {isLoading ? <Spinner /> : <Icon name="input-arrow-right" />}
              </div>
            </button>
          </form>
          <div className="message-container">
            {formik.touched.email && formik.errors.email ? (
              <div className="error-message">{formik.errors.email}</div>
            ) : null}

            {isSubmitted ? <div className="success-message">Done!</div> : null}
          </div>

          <div className="social-links-container">
            <a
              href="https://www.instagram.com/medipups/"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.facebook.com/medipups"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.tiktok.com/@medipups"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              <TikTokIcon />
            </a>
          </div>
        </div>
        <div className="links-sections">
          {footerLinksSections.map(({ sectionTitle, links }) => (
            <div className="links-section" key={sectionTitle}>
              <p className="section-title">{sectionTitle}</p>
              <div className="links">
                {links.map(({ title, to }) => (
                  <Link to={to} key={title} className="section-link">
                    {title}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="disclaimer">
        The information contained within this site is not intended as a substitute for professional
        medical or veterinary advice. Medipups is not intended to diagnose, treat, cure or prevent
        any disease. If your pet has, or you suspect your pet has any medical condition, you are
        urged to consult your veterinarian. Medical conditions can only be diagnosed by a licensed
        veterinarian. These statements have not been evaluated by the Food and Drug Administration.
        *Results May Vary. Not intended for human consumption. Please consult your veterinarian
        regarding any change in treatment or supplementation.
      </div>
      <div className="sub-footer">
        <span className="company">{`Medipups © ${new Date().getFullYear()}`}</span>
        <div className="payments-container">
          <span>Pay securely with</span>
          <img
            src="/images/payment-methods.png"
            alt="payment methods"
            className="payment-methods"
            width={304}
            height={30}
          />
        </div>
        <div className="links">
          <span className="company">{`Medipups © ${new Date().getFullYear()}`}</span>
          <Link to="/terms-and-conditions" className="sub-footer-link">
            Terms & Conditions
          </Link>
          <Link to="/privacy-policy" className="sub-footer-link">
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
