import React from "react";

import { Field, FieldProps, useFormikContext } from "formik";

import Select, { SelectOption, SelectProps } from "@components/common/Select";
import { GroupBase, MultiValue, SingleValue } from "react-select";

const isSingleValue = (
  value: MultiValue<SelectOption> | SingleValue<SelectOption>
): value is SingleValue<SelectOption> => {
  return !Array.isArray(value);
};

const SelectField = <
  IsMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>({
  name,
  ...props
}: Omit<SelectProps<IsMulti, Group>, "onChange"> & { value?: string | undefined }): JSX.Element => {
  const { setFieldValue } = useFormikContext();

  const handleChangeFieldValue = (
    newValue: MultiValue<SelectOption> | SingleValue<SelectOption>
  ): void => {
    setFieldValue(name, isSingleValue(newValue) ? newValue?.value : newValue);
  };

  return (
    <Field name={name}>
      {({ field }: FieldProps<SelectOption>) => {
        const value = props.value || field.value;

        const option = value !== undefined ? props.options?.find((o) => o.value === value) : value;

        return (
          <Select
            {...field}
            {...props}
            name={name}
            value={option}
            onChange={handleChangeFieldValue}
          />
        );
      }}
    </Field>
  );
};

export default SelectField;
