import { CartItem } from "@typings/cart";
import { isEqual } from "lodash";

export const areCartItemsEqual = (a: CartItem, b: CartItem): boolean => {
  return (
    a.isFree === b.isFree &&
    a.linkedTo === b.linkedTo &&
    a.product.id === b.product.id &&
    isEqual(a.promos, b.promos)
  );
};
