import React, { Dispatch, FC, SetStateAction } from "react";

import { useFormikContext } from "formik";

import { CheckoutFormType } from "@pages/checkout";

import Button from "@components/common/Button";

import type { CheckoutFormValues } from "@components/checkout/CheckoutFormContainer";

interface Props {
  setFormType: (newFormType: CheckoutFormType) => void;
  setFocus: Dispatch<SetStateAction<string | undefined>>;
}

const PersonalInfoSummary: FC<Props> = ({ setFormType, setFocus }) => {
  const {
    values,
    values: { email },
  } = useFormikContext<CheckoutFormValues>();
  const fullName = `${values.firstName} ${values.lastName}`;
  const fullAddress = `${values.streetAddress || ""} ${values.streetAddress2 || ""} ${
    values.state
  } ${values.zipCode} United State (US)`;
  const { phoneNumber } = values;

  const options = [
    { label: "Email", value: email, name: "email" },
    { label: "Name", value: fullName, name: "firstName" },
    { label: "Ship To", value: fullAddress, name: "city" },
    {
      label: "Phone",
      value: phoneNumber || "—",
      name: "phoneNumber",
    },
  ];

  const handleChangeFormType = (name: string): void => {
    setFormType("information");
    setFocus(name);
  };

  return (
    <div className="card personal-info-summary">
      <ul className="list-group">
        {options.map(({ label, value, name }) => {
          return (
            <li className="list-group-item" key={label}>
              <div className="item muted label">{label}</div>
              <div className="item value">{value}</div>
              <div className="item">
                <Button color="orange-link" onClick={() => handleChangeFormType(name)}>
                  Change
                </Button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PersonalInfoSummary;
