import { ProductDeliveryFrequency } from "@typings/product";

export const DELIVERY_FREQUENCY_MAP: Record<ProductDeliveryFrequency, string> = {
  NONE: "One Time",
  MONTHLY: "Delivery every Month",
  EVERY_2_MONTHS: "2 month delivery",
  EVERY_3_MONTHS: "3 month delivery",
  EVERY_6_MONTHS: "6 month delivery",
  EVERY_12_MONTHS: "12 month delivery",
};
