import { scroller } from "react-scroll";

interface ScrollTo {
  (name: string): void;
}

const scrollTo: ScrollTo = (name) => {
  scroller.scrollTo(name, {
    duration: 600,
    delay: 0,
    smooth: "easeInOutQuart",
    ignoreCancelEvents: true,
    isDynamic: true,
    offset: 0,
  });
};

export default scrollTo;
